//  引入 全局 需要的 样式文件
import "normalize.css/normalize.css"; // reset css
import "animate.css"; // 动画 css
// 以下俩种css加载顺序不能换  有覆盖样式
import "@/styles/index.less"; // global style
import "@/styles/index.scss"; // global style
import "@/utils/initTheme"; // init theme

import "@/utils/storageNameSpace"; // 存储 命名空间

import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./styles/common.css";
// 查找组件
import emitter from "@/components/common/emitter.mixin.js";
Vue.mixin(emitter);
// ajax方法类
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
// 通用方法加载
import common from "@/utils/common";
import i18n from "./lang"; //  Internationalization
import "./icons"; // icon
import "./errorLog"; // error log
import "./permission"; // permission control
import "./directive/directives.js";

import Toast from "./components/Toast/index.js";
// ajax方法类
// import axios from "axios";
// import VueAxios from "vue-axios";
// Vue.use(VueAxios, axios);
// 将自动注册所有组件为全局组件
import dataV from "@jiaminghi/data-view";

Vue.use(dataV);
Vue.use(ElementUI, { size: "medium" });
Vue.use(Toast);
store.dispatch("setUserType", "user");
// 四舍五入函数
Math.Round = function (num, dec) {
  if (dec === undefined) dec = 2;
  return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
};

Vue.config.productionTip = false;

// 加载自定义配置文件
function getServerConfig () {
  return new Promise((resolve, reject) => {
    axios
      .get("/config.json")
      .then(result => {
        resolve(result.data);
      })
      .catch(error => {
        console.log(error);
        reject();
      });
  });
}

async function init () {
  const config = await getServerConfig();
  if (config.id) {
    config.accessKeyId = config.id;
    config.accessKeySecret = config.key;
  }
  Vue.prototype.common = common;
  Object.assign(Vue.prototype.common, config);
  Vue.prototype.common.domain = process.env.NODE_ENV === "prod" ? config.domain : "http://127.0.0.1:7001";
  // Vue.prototype.common.domain = process.env.NODE_ENV === "prod" ? config.domain : "http://127.0.0.1:8080"; // API接口地址
  Vue.prototype.$go = common.go;
  // 全局自定义语言方法
  Vue.prototype.$tt = p => {
    return ins.$te(p) ? ins.$t(p) : p;
  };
  // 合并语言包
  Vue.prototype.$mergeLang = (lang, langPackage) => {
    return ins.$i18n.mergeLocaleMessage(lang, langPackage);
  };

  const ins = new Vue({
    el: "#app",
    router,
    store,
    i18n,
    render: h => h(App)
  });
}
init();
