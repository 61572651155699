import Vue from "vue";
import VueRouter from "vue-router";
// import foreground from './modules/foreground'
// import Layout from "@/Layout";
import layoutBase from "@/Layout/layout-base";

Vue.use(VueRouter);
export const constantRouterMap = [
  {
    path: "/portal-login",
    component: () => import("@/views/login/index"),
    hidden: true
  },
  {
    path: "/auth-redirect",
    component: () => import("@/views/login/authredirect"),
    hidden: true
  },
  {
    path: "/portal-error401",
    hidden: true,
    name: "401",
    meta: { menuName: "无权限" },
    component: () => import("@/views/errorPage/401")
  },
  {
    path: "/portal-error404",
    hidden: true,
    name: "404",
    meta: { menuName: "找不到该页面" },
    component: () => import("@/views/errorPage/404")
  },
  {
    path: "",
    component: layoutBase,
    redirect: "index",
    children: [
      {
        path: "/",
        component: () => import("@/views/dashboard/index"),
        name: "Index",
        meta: { menuName: "首页", icon: "menu-icon el-icon-yrt-cangku", noCache: true }
      }
    ]
  },
  /* *************** 自定义路由 - 打印页面 ***************** */
  { path: "/print/base/:menu_id/:ids", component: () => import("@/views/sys/print/base"), hidden: true },
  { path: "/print/base-template-id/:menu_id/:tid/:ids", component: () => import("@/views/sys/print/base-template-id"), hidden: true },
  { path: "/print/base2/:type/:ids", component: () => import("@/views/sys/print/base2"), hidden: true },
  { path: "/print/base-tms/:type/:ids", component: () => import("@/views/sys/print/base-tms"), hidden: true },
  { path: "/print/send-list/", component: () => import("@/views/outbound/sale/components/sendlist-print"), hidden: true },
  { path: "/print/outer-list/", component: () => import("@/views/outbound/sale/components/outer-print"), hidden: true },
  {
    path: "/inbound/purchase/print-barcode",
    component: () => import("@/views/inbound/purchase/components/print-barcode"),
    hidden: true
  },
  {
    path: "/inbound/purchase/print-barcode-setting",
    component: () => import("@/views/inbound/purchase/components/print-barcode-setting"),
    hidden: true
  },
  {
    path: "/storage/transfer/position-transfer-print-barcode",
    component: () => import("@/views/storage/transfer/components/position-transfer-print-barcode"),
    hidden: true
  },
  {
    path: "/storage/base/position-group-print-barcode",
    component: () => import("@/views/storage/base/components/position-group-print-barcode"),
    hidden: true
  },
  // 发货单打印
  {
    path: "/outbound/operation/order-pick-bill-print/:ids",
    component: () => import("@/views/outbound/operation/order-pick-bill-print"),
    hidden: true
  },
  // 要货单波次打印
  {
    path: "/outbound/require/allocation-pick-bill-print/:ids",
    component: () => import("@/views/outbound/require/allocation-pick-bill-print"),
    hidden: true
  },
  // pi打印
  {
    path: "/erp/sale/pi-print",
    component: () => import("@/views/erp/sale/pi-print"),
    hidden: true
  },
  /* *************** 大数据 ***************** */
  {
    path: "/bigData/3d-garden",
    component: () => import("@/views/bigData/3d-garden"),
    hidden: true
  },
  {
    path: "/bigData/storage-performance",
    component: () => import("@/views/bigData/storage-performance"),
    hidden: true
  },
  {
    path: "/bigData/storage-ai",
    component: () => import("@/views/bigData/storage-ai"),
    hidden: true
  },
  {
    path: "/bigData/storage-progress",
    component: () => import("@/views/bigData/storage-progress"),
    hidden: true
  },
  {
    path: "/bigData/tms-playback",
    component: () => import("@/views/bigData/tms-playback"),
    hidden: true
  },
  {
    path: "/bigData/tms-runtime",
    component: () => import("@/views/bigData/tms-runtime"),
    hidden: true
  },
  {
    path: "/ureport/print",
    component: () => import("@/views/sys/dev-tools/bi-designer/viewer"),
    hidden: true
  }
];

// 创建 路由 对象
const createRouter = () =>
  new VueRouter({
    mode: "history", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRouterMap
  });

const router = createRouter();

// 动态路由更新 see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export const asyncRouterMap = [
  // 动态加载 路由
  // 未了 子项目也能出现 404 和401 页面
  // {
  //   path: '/sub-app1',
  //   component: Layout
  // },
  {
    path: "test",
    component: layoutBase,
    meta: { menuName: "测试菜单", icon: "home", noCache: true },
    children: [
      {
        path: "/inbound/purchase/quotation",
        component: () => import("@/views/inbound/purchase/quotation"),
        name: "inbound-purchase-quotation",
        meta: { menuName: "入库计划单", icon: "home", noCache: true }
      }
    ]
  },
  { path: "*", redirect: "/portal-error404", hidden: true }
];

export default router;
